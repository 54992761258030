<template>
  <div class="filter" v-click-outside="hideFilter">
    <header class="filter__header">
      <IconButton class="button--public button--public-right" @click="open = !open" text="Filter">
        <template #right><IconFilter></IconFilter></template>
      </IconButton>
    </header>
    <div class="filter__body" v-if="open" id="scroll-block">
      <ValidationObserver ref="observer" tag="div" class="filter__body_content">
        <div class="filter__group" v-for="item in items" :key="item.group">
          <div class="filter__group_header">
            <p class="text">{{ item.group }}</p>
            <span class="icon" :class="{ 'icon--down': item.open }" @click="item.open = !item.open"
              ><IconArrowTop width="10" height="6"></IconArrowTop
            ></span>
          </div>
          <div
            class="filter__group_body filter__group_body--open-xl"
            :class="{ 'filter__group_body--open': item.open }"
          >
            <div class="filter__group_body-content">
              <template v-if="item.type === 'range'">
                <div class="filter__group_body-row" style="padding-left: 10px; margin-bottom: 0px">
                  <ValidationProvider
                    v-for="(i, id) in item.items"
                    :key="id"
                    tag="div"
                    :rules="{
                      more_then: i.key === 'price_start' ? price.price_end : false,
                      less_then: i.key === 'price_end' ? price.price_start : false,
                    }"
                    v-slot="{ errors }"
                  >
                    <BaseNumberInput
                      class="form-control--simple form-control--xxs"
                      :placeholder="i.title"
                      :validateError="errors[0]"
                      symbol="$"
                      v-model="price[i.key]"
                      :element="price[i.key]"
                      @input="priceChange({ i, event: $event })"
                    />
                  </ValidationProvider>
                </div>
              </template>
              <template v-else>
                <Tag
                  class="tag--public"
                  :class="{
                    'tag--selected': i.selected,
                    'tag--periods': item.key === 'periods',
                  }"
                  v-for="(i, idx) in item.items"
                  :key="`${i.title}-${idx}`"
                  :value="i.title"
                  @click="$emit('selectFilter', { key: item.key, filter: i })"
                />
              </template>
            </div>
          </div>
        </div>
      </ValidationObserver>
      <div class="filter__group filter__group--buttons">
        <BaseButton
          class="button--public button--public-xs button--outline-white button--uppercase"
          text="reset"
          @click="$emit('clear')"
        ></BaseButton>
        <BaseButton
          class="button--public button--public-xs button--outline-white button--uppercase"
          text="apply"
          @click="$emit('apply')"
        ></BaseButton>
      </div>
    </div>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import IconButton from "@/components/buttons/IconButton.vue";
import TogglePlusMinus from "@/components/buttons/TogglePlusMinus.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import IconFilter from "@/components/icons/IconFilterPublic.vue";
import IconClose from "@/components/icons/IconClose.vue";
import Tag from "@/components/Tag.vue";
import BaseNumberInput from "@/components/inputs/BaseNumberInput.vue";
import IconArrowTop from "@/components/icons/IconArrowTop.vue";
import ClickOutside from "vue-click-outside";

export default {
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    IconButton,
    TogglePlusMinus,
    BaseButton,
    IconFilter,
    Tag,
    IconClose,
    BaseNumberInput,
    IconArrowTop,
  },
  data() {
    return {
      open: false,
      price: {
        price_start: "",
        price_end: "",
      },
    };
  },
  watch: {
    open(val) {
      this.$emit("open", val);

      this.items.forEach(el => {
        if (el.group === "Price") {
          el.items.forEach(element => {
            this.price[element.key] = element.selected ? element.val : "";
          });
        }
      });
    },
    items: {
      handler: function (val, oldVal) {
        this.baseSetup(val);
      },
      deep: true,
    },
  },
  methods: {
    baseSetup(val) {
      val.forEach(el => {
        if (el.group === "Price") {
          el.items.forEach(element => {
            this.price[element.key] = element.val;
          });
        }
      });
    },
    async priceChange({ i, event }) {
      this.price[i.key] = event;

      let valid = await this.priceValidation();

      this.$emit("selectFilter", {
        key: i.key,
        filter: i,
        val: event,
        valid,
      });
    },
    priceValidation() {
      let valid = false;
      if (Number(this.price.price_end) && Number(this.price.price_start)) {
        valid = Number(this.price.price_start) < Number(this.price.price_end) ? true : false;
      } else valid = true;

      return valid;
    },
    hideFilter() {
      this.open = false;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
<style lang="scss" scoped>
.filter {
  position: relative;
  margin: 5px 0;
  .text {
    @include publictext($fw: 700, $lh: 1);
    text-transform: uppercase;
    margin: 2px 2rem 0 0;
    color: $white;
    @media only screen and (max-width: $lg) {
      font-size: 14px;
    }
  }
  &__header {
    display: flex;
    align-items: center;
  }
  &__body {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: -10px;
    right: -2px;
    transform: translate(0%, 100%);
    min-width: 500px;
    max-width: 500px;
    height: 57rem;
    background-color: rgb(17, 17, 17);
    border: 1px solid $white;
    border-radius: 14px;
    padding: 15px 0 10px;
    z-index: 3;
    @media screen and (max-width: $xs) {
      min-width: 86vw;
    }
    &_content {
      overflow-y: auto;
      height: 100%;
    }
  }
  &__group {
    &--buttons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 10px;
      margin: auto 0 0;
      .button {
        margin: 0 1.1rem 10px;
        @media screen and (max-width: $xs) {
          width: 100%;
        }
      }
    }
    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.65rem;
        height: 3.65rem;
        border: 0.5px solid $white;
        border-radius: 4px;
        cursor: pointer;
        svg {
          transform: rotate(180deg);
          ::v-deep g {
            fill: $white;
            transition: all 0.3s ease-in-out;
          }
        }
        &--down {
          transform: rotate(0deg);
        }
      }
    }
    &_body {
      max-height: 0;
      overflow-y: auto;
      transition: all 0.3s ease;
      &-content {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 60px 15px 10px;
        @media screen and (max-width: $xs) {
          padding: 10px 30px 15px 10px;
        }
      }
      &--open {
        max-height: 30rem;
        &.filter__group_body--open-xl {
          max-height: 40rem;
        }
      }
      &-row {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 15px;
        div {
          @media screen and (max-width: $xs) {
            width: 100%;
            max-width: 200px;
          }
        }
        div:first-of-type {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
